import { tangJSBridge } from './jsBridge'


/**
 * 登录
 * @param {Function} loginCallBackName 点击登录按钮之后的回调,客户端传参 {result:1 登录成功/其他 取消登录, message:'描述', data:{token: '' ,uid:'',nickname:'',avatar:'头像地址'}}
 * @return {JSON} {result:1, message:'ok' -1 ：取消 0 ： 登录失败}
 */
export function login() {
    return tangJSBridge("app/login", {}, "loginCallBackName")
}


/**
 * 查询单个应用是否已安装
 * @param {String} os ios/android
 * @param {String} name IOS传scheme android传包名
 * @return {JSON} {result:1, message:'ok', data:{code:1安装 -1未安装 }}
 */
export function isAppInstalled(name: string) {
    return tangJSBridge("app/isAppInstalled", Object.assign({ os: 'android', name }))
}


/**
 * 分享
 * @param {String} to 分享渠道， 车轮陈友/微信好友/朋友圈/QQ/新浪微博/短信
 * @param {Function} shareCallBackName 分享之后的回调函数，客户端传参{result:1分享成功/其他 取消,message:'描述'}
 * @return {JSON} {result:1}
 */

// export function setTangShareData(data) {

//     let imgUrl = data.wxTimeLine.imgUrl;
//     if (!imgUrl.startsWith("http")) {
//         data.wxTimeLine.imgUrl = "https:" + imgUrl;
//     }

//     imgUrl = data.wxMessage.imgUrl;
//     if (!imgUrl.startsWith("http")) {
//         data.wxMessage.imgUrl = "https:" + imgUrl;
//     }
//     window['TANG_SHOW_MENU_ITEMS'] = ['menu:share:wxMessage', 'menu:share:wxTimeline', 'menu:copyUr', 'menu:openWithBrowser', 'menu:refresh']
//     window['TANG_SHARE_DATA_WXTIMELINE'] = data.wxTimeLine
//     window['TANG_SHARE_DATA_WXMESSAGE'] = data.wxMessage
//     window['TANG_SHARE_DATA_QQ'] = data.qq
// }


/**
 * 默认设置
 */
// export function setAppDefault() {
//     setTangDefaultShareData()
// }


// export function setTangDefaultShareData() {
//     let logo = "https://imgcdn-mm.catsays.cn/uploads/resource/202101/05/1609814788_84e258d7f69f1eb8d5f545cba48ffd40.png";
//     let href = process.env.NODE_ENV != 'production' ? "https://mm.catsays.cn/dev/calendar/index.html#/accept" : "https://tanggua.net/s/FbHp9l";
//     let wxTimeline = {
//         title: "好运日历！",
//         link: href,
//         imgUrl: logo
//     };
//     let wxMessage = {
//         title: "好运日历！",
//         desc: "好运日历， 赚大钱！",
//         link: href,
//         imgUrl: logo
//     };
//     setTangShareData({
//         wxTimeLine: wxTimeline,
//         wxMessage: wxMessage
//     });
// }


export function shareMessage(to: string) {
    return tangJSBridge("ui/shareMessage", Object.assign({ to }), 'shareCallBackName')
}


/**
 * 使用浏览器打开
 * @param {String} url 网址，需要提前encode
 * @return {JSON} {result:1}
 */

export function openWithBrowser(url: string) {
    return tangJSBridge("ui/openWithBrowser", Object.assign({ url }))
}


/**
 * 使用native刷新
 * @无需返回值
 */
export function refresh() {
    return tangJSBridge("ui/refresh", {})
}


/**
 * 绑定微信
 * @param {String} bindCallBackName 绑定回调函数，客户端传参{result: 1成功, message:'描述'}
 * @return {JSON} {result:1}
 */

export function bindWeixin() {
    return tangJSBridge("ui/bindWeixin", {}, 'bindCallBackName')
}



/**
 * 是否绑定手机号
 * @return {JSON} {result:1, data:{code:1已绑定 -1未绑定}}
 */
export function hadBoundPhone() {
    return tangJSBridge("data/hadBoundPhone", {})
}


/**
 * 绑定手机号
 * @param {String} bindCallBackName 绑定回调函数，客户端传参｛result: 1成功,message:'描述', data : {phone:手机号}｝ 如果用户已经绑定了手机号，那客户端直接调用callback函数
 * @return {JSON} {result:1}
 */
export function bindPhone() {
    return tangJSBridge("ui/bindPhone", {}, "bindCallBackName")
}


/**
 * 启动app
 * @param {String} name IOS传scheme android传包名
 * @return {JSON} {result:1, message:'ok', data:{code:1安装 -1未安装 }}
 */
export function launchApp(name: string) {
    return tangJSBridge("app/launchApp", { name })
}


/**
 * 复制
 * @param {String} text 需要复制的内容
 * @return {JSON} {result:1, message:'ok', data:{code:1成功 -1异常}} 如果复制成功，需要有系统自带的默认提示显示哦
 */

export function copy(text: string) {
    return tangJSBridge("ui/copy", { text })
}


/**
 * 强制客户端到服务端验证token（loading由前端处理），成功或失败都会回调
 * @param {String} verifyCallbackName 绑定回调，客户端传参 {result: 1有效 -1无效， message:'描述', data:{token:有效则返回，前端更新token为此token}}
 * @return {JSON} {result:1, message:'ok', data:{code:1成功 -1异常}}
 */

export function verifyToken() {
    return tangJSBridge("data/verifyToken", {}, 'verifyCallbackName')
}


/**
 * 选取图片 ，目前只支持单张图片
 * @param {String} type 0 默认值，底部弹窗，同时显示 拍照和从手机相册选择 1 直接牌照 2直接从手机相册显示
 * @param {String} defaultImage 默认图片url
 * @param {String} chooseCallbackName 绑定回调，客户端传参 {result: 1 图片上传到服务器成功， message:'描述', data:{url:'图片url'}} 
 * @return {JSON} {result:1, message:'ok', data:{code:1成功， 2，取消， -1异常}} 
 */
export function chooseImage(params: object) {
    return tangJSBridge("device/chooseImage", Object.assign({ type: 0, defaultImage: '' }, params), 'chooseCallbackName')
}


/**
 * @param {String} schema 链接（schema）
 * @return {JSON} {result:1, message:'ok', data:{code:1打开成功 -1打开失败}} 
 */
export function schema(schema: string) {
    return tangJSBridge("app/openSchema", { schema })
}




/**
 * 关闭当前web页面
 */
export function closeWebView() {
    return tangJSBridge("app/closeWebView", {})
}

/**
 * 显示隐藏标题栏
 */
export function showTitleBar() {
    return tangJSBridge("ui/showTitleBar", {})
}

export function hideTitleBar() {
    return tangJSBridge("ui/hideTitleBar", {})
}



/**
 * 触发震动
 */
export function vibration() {
    return tangJSBridge("ui/vibration", {})
}


/**
 * @param {String} shakeCallbackName 摇一摇结束后回调 {result: 1 结束}
 * @return {JSON} {result:1, message:'ok', data:{code:1成功 -1异常}}
 */
export function shakeItOff() {
    return tangJSBridge("ui/shakeItOff", {}, "shakeCallbackName")
}


/**
 * 日期选择控件
 * @param {String} minDate 最小日期，由yyyy-mm-dd表示 系统默认
 * @param {String} maxDate 最大日期，由yyyy-mm-dd表示 系统默认
 * @param {String} curDate 选中日期，由yyyy-mm-dd表示 默认当前日期
 * @param {String} pickCallbackName 日期选择结束后回调 {result: 1 结束， data:{date:'2015-05-05'}}   如格式 yyyy-mm-dd 匹配 2016-05-03
 * @return {JSON} {result:1, message:'ok', data:{code:1成功 -1异常}}
 */
export function datepicker(params = {}) {
    return tangJSBridge("ui/datepicker", params, "pickCallbackName")
}

/**
 * 时间选择控件
 * @param {String} minTime 最小日期，由h:i表示 系统默认
 * @param {String} maxTime 最大日期，由h:i表示 系统默认
 * @param {String} curTime 选中日期，由h:i表示 默认当前时间
 * @param {String} pickCallbackName 日期选择结束后回调 {result: 1 结束， data:{date:'12:33'}}  如格式h:i 匹配11:40
 * @return {JSON} {result:1, message:'ok', data:{code:1成功 -1异常}}
 */
export function timepicker(params = {}) {
    return tangJSBridge("ui/timepicker", params, "pickCallbackName")
}



/**
 * 下拉刷新控件，注：客户端只提供UI和交互效果，刷新逻辑由H5自行控制
 *  @param  {boolean}     isRefresh    开启刷新返回true ,结束返回false
 * @param {String} onRefreshCallback 刷新结束后回调 {result: 1 结束}
 * @return {JSON} {result:1, message:'ok', data:{code:1成功 -1异常}}
 */

export function pullRefresh(isRefresh: boolean) {
    return tangJSBridge("ui/pullRefresh", { isRefresh }, "onRefreshCallback")
}


/**
 * 获取网络状态
 */
export function netStatus() {
    return tangJSBridge("app/netStatus", {})
}



/**
 * 使用内部浏览器打开
 * @param {String} url 网址，当url包含名为'cl_in_jsurl'的参数时，客户端会将该参数的值，作为每次加载页面时注入js的地址
 * @param {boolean} isEncode 是否需要客户端encode url 
 * @param {boolean} isPop 是否Pop当前页面 （仅iOS8.2.8及以上支持）
 * @param {boolean} isAnimation 当isPop为YES时，打开新页面是否需要动画效果 （仅iOS8.2.8及以上支持）
 * @return {JSON} {result:1}
 */
export function openInnerBrowser(params = {}) {
    return tangJSBridge("ui/openInnerBrowser", params)
}


/**
 * 修改标题
 * @param {String} title 标题
 */
export function setTitle(title: string) {
    return tangJSBridge("ui/setTitle", { title })
}


/**
 * 保存图片到本地相册
* @param {String} pic 需要保存的图片url
 * @param {String} saveCallBackName ｛result: 1 成功, 其它表示失败 }
 * @return {JSON} {result:1}
 */
export function savePic(url: string) {
    return tangJSBridge("data/savePic", { url }, 'saveCallBackName')
}



/**
 * 使用微信浏览器打开
 * @param {String} url 网址
 * @param {boolean} isEncode 是否需要客户端encode url 
 * @param {Function} openCallBack 请求之后的回调
 * @return {JSON} {result:1 成功，2 未安装微信， 3 打开失败}
 */
export function openWithWechatBrowser(params = {}) {
    return tangJSBridge("ui/openWithWechatBrowser", params, 'openCallBack')
}


/**
 *
 * 自定义statusbar状态及颜色
* @param {String} type 1:隐藏  2显示 3:黑色 4:白色
* @param {Function} changeStatusbarCallBack 请求之后的回调
 * @return {JSON} {result:1, message:'ok', data:{code:1成功 -1异常}}
*/
export function statusbarChange(type = 3) {
    return tangJSBridge("statusbar/change", { type }, 'changeStatusbarCallBack')
}


/**
 *
 * 获取statusbar的高度
* @param {Function} statusbarHeightCallBack请求之后的回调 客户端传参数｛result: 1成功,message:'描述', data : {height:高度}｝
 * @return {JSON} {result:1}
*/
export function statusbarHeight() {
    return tangJSBridge("statusbar/getHeight", {}, 'statusbarHeightCallBack')
}


/**
 * 短视频任务
* @param {Function} shortVideoTaskCallBackName
 请求之后的回调 客户端传参数｛result: 1成功,message:'描述', data : {code:1}｝code的值1表示完成
 * @return {JSON} {result:1} 
*/
export function shortVideo() {
    return tangJSBridge("shortVideo/task", {}, 'shortVideoTaskCallBackName')
}



/**
 * 跳转页面
* @param {String}  url
 请求之后的回调 客户端传参数｛result: 1成功,message:'描述', data : {code:1}｝code的值1表示完成
 * @return {JSON} {result:1} 
*/
export function goPage(url: string) {
    return tangJSBridge("ui/handleSchema", { url })
}


/**
 * 系统弹框
* @param {int}  awardType //1代表金币， 2代表红包
* @param {String}  awardTaskCode: “xxx”,//任务唯一标识
* @param {int}  awardAmount: 2,//整数都支持
* @param {int} awardSent: 0, //0代表需用户手动待领取，1代表已经发放给用户账号
* @param {int} awardDoubleCount  //翻倍倍数， <=1不可翻倍
* @param {int} awardShowClose  //0 不显示右上角关闭按钮
* @param {int} awardShowGiveup //0 不展示放弃翻倍
* @param {int} awardShowStatus // 1展示金币/现金余额，0 不展示
* @param {int} adType  //0 不显示广告，>0 代表广告样式编号
* @param {int} adDelayRate //0-100 广告展示延迟率，50代表50%；默认延迟2秒
* @param {int} adClickRate //0-100误点率
* @param {String} adScene //广告播放场景；看完视频广告后，客户端自动上报
* @param {Function} awardCallBackName 点按钮之后的回调,客户端传参 {result:1 成功, message:'描述', data:{ awardTaskCode: “xxx”， operation: 'close' }} // awardTaskCode 原样回传，  operation: 用户操作：double 奖励翻倍， get 立即领取， close 关闭弹框，giveupDouble 放弃翻倍
 * @return {JSON} {result:1} 
*/
export function showAwardPop(params = {}) {
    return tangJSBridge("ui/showAwardPop", Object.assign({}, { adType: 1 }, params), 'awardCallBackName')
}




/**
 *  播放激励视频广告
 * @param {String} id 播放的广告id 
 * @param {type} 0: topon的ID，默认0
 * @param {String} adScene  //来源场景, 播放完自动上报服务端，做相应处理 
 * @param {String} showRewardedVideoCallback 绑定回调函数，客户端传参{"result": "1"} 其中1表示广告正常播放完成，-1表示没有广告，-2表示广告播放错误
 * @return {JSON} {result:1}
 */
export function showRewardedVideo(params = {}) {
    return tangJSBridge("ads/showRewardedVideo", params, 'showRewardedVideoCallback')
}


/**
 *  展示全屏广告
 * @param {String} id 播放的广告id ； 不传为默认id
 * @param {type} 0: topon的ID，默认0
 * @return {JSON} {result:1}
 */
export function showFullscreen(params = {}) {
    return tangJSBridge("ads/showFullscreen", params)
}


/**
 * 展示插屏广告
 * @param {String} id 播放的广告id; 不传为默认id 
 * @param {type} 0: topon的ID，默认0
 * @return {JSON} {result:1}
 */
export function showInterstitial(params = {}) {
    return tangJSBridge("ads/showInterstitial", params)
}
