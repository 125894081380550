import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { goPage } from '@/utils'
import VConsole from 'vconsole'
//rem适配
import 'amfe-flexible'
// 导入vant组件库
import vant from './utils/vant'
import { Lazyload } from 'vant';

Vue.use(Lazyload, {
  lazyComponent: true,
});

Vue.config.productionTip = false;

interface Plugin {
  plugin: any,
  options?: object
}

const Plugin: Array<Plugin> = [{ plugin: vant }]
Plugin.forEach(item => {
  Vue.use(item.plugin, item.options)
})


// 解决移动端300毫秒延迟
import FastClick from 'fastclick'
interface FastClickType {
  attach?: any;
}
if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function () {
    (FastClick as FastClickType).attach(document.body);
  }, false);
}

Vue.prototype.$goPage = goPage;
// if (process.env.NODE_ENV != 'production') {
// let vConsole = new VConsole();
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
