import cookie from "@/utils/store/cookie";

export function trim(str: string): string {
    return String.prototype.trim.call(str);
}

export function isType(arg: any, type: any) {
    return Object.prototype.toString.call(arg) === "[object " + type + "]";
}

export function isLucky() {
    // let AppName = cookie.getString("tang_appName") ? cookie.getString("tang_appName").toLowerCase() : '';
    // return AppName === 'luckycalendar' || AppName === 'luckyweather' || AppName === 'luckywifi' || AppName === 'luckyjob'

    return cookie.getString("tang_acToken") ? true : false
}

export function goPage(_this: any, url: any, replace: any) {
    if (url) {
        let urlPro = url.substring(0, 4);
        if (urlPro === "http" || urlPro == 'tang' || urlPro == 'jz:/') {
            window.location.href = url;
            return;
        }
        if (_this.$route.fullPath === url) {
            window.location.reload()
            return
        }
        if (replace) {
            _this.$router.replace({
                path: url
            });
        } else {
            _this.$router.push({
                path: url
            });
        }
    }
}