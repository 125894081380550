import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { isLucky } from '@/utils'
import { setTitle } from '@/utils/tang_api.2.0'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Article' }
  },
  {
    path: "/article",
    name: "Article",
    meta: {
      title: 'BLANCO',
    },
    component: () => import('@views/Article/Articles.vue'),
  },
  {
    path: "/articleDetail",
    name: "ArticleDetail",
    meta: {
      title: 'BLANCO',
    },
    component: () => import('@views/Article/Detail.vue'),
  },

];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, form, next) => {
  const {
    title
  } = to.meta;
  (window as any).TANG_SHOW_OPTION_MENU = 0
  let titleName = title || process.env.VUE_APP_NAME || "兼职";
  if (isLucky()) {
    setTitle(titleName)
  } else {
    document.title = titleName
  }
  next();

});
export default router;
