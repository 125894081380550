// 自动按需引入 Vant 组件
import {
    Button, Toast, Field, Uploader, Popup, Swipe, SwipeItem, Image as VanImage, Icon, NavBar, Tab, Tabs, List
} from 'vant'
let vantUIs = [Button, Toast, Field, Uploader, Swipe, SwipeItem, Popup, VanImage, Icon, NavBar, Tab, Tabs, List]
// 批量注册
export default {
    install(Vue: any) {
        vantUIs.forEach(vantUI => [
            Vue.component(vantUI.name, vantUI)
        ])
    }
}