import Vue from "vue";
import Vuex from "vuex";
import cookie from "@/utils/store/cookie";
import { USERINFO, COMMONCONFIG, LOC_TOKEN } from "@/config";
Vue.use(Vuex);


interface STRING {
  [token: string]: string
}

export default new Vuex.Store({
  state: {
    token: cookie.getString("tang_acToken") || '',
  },
  mutations: {},
  actions: {},
  modules: {},
});
