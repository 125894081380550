<template>
  <div class="app" v-cloak>
    <!-- <transitions> -->
    <keep-alive :max="10">
      <router-view
        class="router"
        ref="router"
        v-if="$route.meta.keepAlive"
        :key="$route.fullPath"
      ></router-view>
    </keep-alive>
    <router-view
      class="router"
      ref="router"
      v-if="!$route.meta.keepAlive"
      :key="$route.fullPath"
    ></router-view>
    <!-- </transitions> -->
  </div>
</template>
<script>
export default {
  name: "App",
  created() {},
};
</script>

<style lang="scss">
</style>
