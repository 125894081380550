import { isLucky } from './index'
import { Toast } from 'vant'

/**
 * JSBridge调用原生功能
 * @param path 完整路径
 * @param param 参数
 * @param schema 当前请求的schema
 */


let nativeInvokeIndex: number = 0;
export function nativeCall(path: string, param: any, schema = "tangJSBridge") {
    // 非糖瓜APP之内，不发起任何请求
    if (!isLucky()) {
        return;
    }
    // schema的完整URL
    let url = `${schema}://${path.replace(/^\/*|\/*$/g, "")}`;
    // 模拟发送请求
    const nativeCall = (url: string) => {
        let temp = document.createElement("iframe");
        document.body.appendChild(temp);
        temp.style.display = "none";
        // 安全的删除临时对象
        let hasRemoved = false;
        const removeIframe = () => {
            if (!hasRemoved) {
                hasRemoved = true;
                temp.remove();
            }
        };
        /**
         * 如果时ios离线H5，需要做包装
         */
        // if (is.ios() && info.offline) {
        //     url = `https://${window.location.host}?cl_offline_schema=${encodeURIComponent(url)}`;
        // }
        temp.src = url;
        temp.onload = removeIframe;
        /**
         * 清理无效的iframe
         */
        window.setTimeout(removeIframe, 300);
    };

    // 如果没有参数选项，直接发送请求
    if (!param || typeof param !== "object") {
        nativeCall(url);
        return;
    }
    let hashIndex;
    let pairs = [];
    for (let key in param) {
        if (!param.hasOwnProperty(key)) {
            continue;
        }
        if (typeof param[key] === "function") {
            nativeInvokeIndex += 1;
            let methodsName: string = `__MCL_CALLBACK_${nativeInvokeIndex}`
            interface WINDOW {
                methodsName: Function,
            }
            (window as any)[methodsName] = param[key];
            if (key === "callback") {
                hashIndex = nativeInvokeIndex;
            }
            else {
                pairs.push(`${encodeURIComponent(key)}=${nativeInvokeIndex}`);
            }
        }
        else {
            pairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(param[key])}`);
        }
    }
    // 判断是否有参数
    if (pairs.length > 0) {
        url += `?${pairs.join("&")}`;
    }
    // 判断是否有hash统一回调
    if (typeof hashIndex === "number") {
        url += `#${hashIndex}`;
    }
    // 发起调用
    nativeCall(url);
}

/**
 * JSBridge 封装
 * @param {String} apiName api名称  例如： 唤醒APP登录 --> app/login
 * @param {JSON} params 参数
 * @return {Function} 回调的方法名  例如： 唤醒APP登录 --> loginCallBackName
 */
interface result {
    result: string
}

export function tangJSBridge(apiName: string, params: any, callBack = 'callback') {
    console.log('JSBridge apiName-----', apiName)
    console.log('JSBridge params-----', params)
    return new Promise((resolve, reject) => {
        if (!isLucky()) {
            Toast('请在APP内操作')
            return reject({ errMsg: '请在APP内操作' })
        }

        nativeCall(apiName, Object.assign(params, {
            [callBack](res: result) {
                if (parseInt(res.result, 10) === 1) {
                    resolve(res);
                }
                else {
                    reject(res)
                }
            },
        }));
    })
}